export const DocumentationType = {
    CONTRATO_VENTA: 1,
    RESERVA: 2,
    COMPRA_DIRECTA: 3,
    GESTION_VENTA:4
};
export const DocumentationLetter = {
    [DocumentationType.CONTRATO_VENTA]:"V",
    [DocumentationType.RESERVA]:"R",
    [DocumentationType.COMPRA_DIRECTA]:"C",
    [DocumentationType.GESTION_VENTA]:"G",
}