import { MdInfoOutline, MdWarningAmber, MdErrorOutline } from "react-icons/md";

import "./index.css";

const InfoBox = ({ leftIcon, text, className }) => (
  <div className={`infoBoxWrapper ${className}`}>
    {leftIcon}
    <span>{text}</span>
  </div>
);

export const InformativePanel = ({ text }) => (
  <InfoBox
    leftIcon={<MdInfoOutline size={22} color="#17a2b8" />}
    text={text}
    className="infoBoxInfo"
  />
);

export const WarningPanel = ({ text }) => (
  <InfoBox
    leftIcon={<MdWarningAmber size={22} color="#EDDF5D" />}
    text={text}
    className="infoBoxWarning"
  />
);

export const ErrorPanel = ({ text }) => (
  <InfoBox
    leftIcon={<MdErrorOutline size={22} color="#ED0530" />}
    text={text}
    className="infoBoxError"
  />
);
