import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";

import { config } from "../config/environment.config";
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setTokens,
} from "../store/AccessTokenStore";
import { login, refreshTokens } from "../services/AuthService";
import RolType from "../constants/roles";
import { useNavigate } from "react-router-dom";
import { post } from "services/axiosCall";
import {
  AdminSocketType,
  ICompanieOption,
  IDealershipOption,
  IUserData,
  LoginDTO,
  UserCompaniesResponse,
  UserDealershipResponse,
} from "types";
import { axiosInstance } from "services/BaseService";
import { errorToast } from "helpers/toastFunction";

interface User {
  id: number;
  email: string;
  roles: number[];
  dealerships: number[];
  dealershipOptions: IDealershipOption[];
  companiesOptions: ICompanieOption[];
}

type RedirectLocationState = {
  redirectTo: Location;
};

interface UserCtxState {
  socket: AdminSocketType | null;
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  logIn: (
    payload: LoginDTO,
    locationState: RedirectLocationState
  ) => Promise<void>;
  logOut: () => void;
}

const UserContext = createContext<UserCtxState>({} as UserCtxState);

export const UserContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const user = useRef<User | null>(null);
  const socketRef = useRef<AdminSocketType | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    getAccessToken() ? true : false
  );

  const initSocket = useCallback(() => {
    if (!socketRef.current)
      socketRef.current = io(
        `${config.API_HOST.split("/api/v1/management")[0]}`,
        {
          auth: {
            token: getAccessToken(),
          },
          transports: ["websocket", "polling", "flashsocket"],
          reconnection: true,
          reconnectionDelay: 500,
          reconnectionDelayMax: 50,
        }
      ) as AdminSocketType;
  }, []);

  const logOut = useCallback(() => {
    removeTokens();
    user.current = null;
    setIsAuthenticated(false);
    navigate("/");

    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
    }
  }, [navigate]);

  const getUserDealershipsOptions = async (): Promise<IDealershipOption[]> => {
    const responseLocals: UserDealershipResponse = await post("/api/filtros", {
      dataCall: { data_query: "locales_citar", data_call: null },
    });

    return responseLocals?.map((item) => ({
      label: item.nombre,
      value: item.id,
      identificador: item.literal,
    }));
  };

  const getUserCompaniesOptions = async (): Promise<ICompanieOption[]> => {
    const responseCompanies: UserCompaniesResponse = await post(
      "/api/filtros",
      {
        dataCall: { data_query: "empresas_existentes", data_call: null },
      }
    );

    return responseCompanies?.map((item) => ({
      label: item.nombre,
      value: item.id,
    }));
  };

  const getUserData = async (): Promise<IUserData | undefined> => {
    try {
      const userData: IUserData = await axiosInstance.get("/users/me");
      return userData;
    } catch (err) {
      errorToast("Error consiguiendo los datos del usuario");
    }
  };

  const loadUserInfo = useCallback(async () => {
    const token = getAccessToken();
    const refreshToken = getRefreshToken();

    if (token) {
      try {
        const userData = await getUserData();

        if (userData) {
          const dealershipOptions = await getUserDealershipsOptions();
          const companiesOptions = await getUserCompaniesOptions();

          user.current = {
            id: userData.id,
            email: userData.email,
            roles: userData.rolIds,
            dealerships: userData.dealershipIds,
            dealershipOptions,
            companiesOptions,
          };

          initSocket();
        }
      } catch (err) {
        logOut();
      }
    } else if (refreshToken) {
      await refreshTokens();
      await loadUserInfo();
    }

    setIsLoading(false);
  }, [initSocket, logOut]);

  const logIn = useCallback(
    async (payload: LoginDTO, locationState: RedirectLocationState) => {
      try {
        const response = await login(payload);
        setTokens(response);
        await loadUserInfo();

        setIsAuthenticated(true);

        if (
          locationState === null ||
          locationState.redirectTo.pathname === "/" ||
          locationState.redirectTo.pathname === "/login"
        ) {
          user.current?.roles.includes(RolType.ACCOUNTING)
            ? navigate("/historial")
            : navigate("");
        } else {
          navigate(
            `${locationState.redirectTo.pathname}${locationState.redirectTo.search}`
          );
        }
      } catch (err) {
        throw err;
      }
    },
    [navigate, loadUserInfo]
  );

  useEffect(() => {
    (async () => !user.current && (await loadUserInfo()))();
  }, [user, loadUserInfo]);

  const contextValue: UserCtxState = useMemo(
    () => ({
      socket: socketRef.current,
      user: user.current,
      isLoading,
      isAuthenticated,
      logIn,
      logOut,
    }),
    [isLoading, isAuthenticated, logIn, logOut]
  );
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
