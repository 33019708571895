import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ModalContracts.css";
import { axiosInstance } from "services/BaseService";
import { errorToast } from "helpers/toastFunction";

const ModalContracts = ({ show, handleClose, selectedContract }) => {
  const navigate = useNavigate();

  const handleAccept = async () => {
    try {
      const newTask = await axiosInstance.post(
        `/contract/${selectedContract.id}/task`
      );

      navigate(`/gestion/${newTask.id}`);
    } catch (err) {
      errorToast(err?.response?.data?.code || "Error al crear la gestión");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="modal_header">
        <h5>Generar nueva gestión</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          Se va a generar una nueva gestión a partir del contrato seleccionado:
          <br />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal_footer_buttons">
          <Button className="btn_custom" onClick={handleClose}>
            Cancelar
          </Button>
          <Button className="btn_custom" onClick={handleAccept}>
            Aceptar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalContracts;
