export const Origin = {
  PARTICULAR: 1,
  AUTONOMO: 2,
  EMPRESA_COLABORADORA: 3,
  EMPRESA_NO_COLABORADORA: 4,
};

export const Pago = {
  SIN_TRAMITAR: 1,
  FIRMA_PENDIENTE: 2,
  COMPLETADO: 3,
  REVISADO: 4,
};

export const Taxes = {
  IVA_DEDUCIBLE_21: 1,
  IVA_DEDUCIBLE_21_SOBRE_50: 2,
  IVA_INTRACOMUNITARIO: 3,
  REBU: 4,
};

export const Roles ={
  CENTRAL_ADMIN: 1,
  GENERAL_ADMIN: 2,
  ACCOUNTING: 3,
}

export const TypeTask = {
  Compra: 1,
  Cierre_gestión_de_venta: 2,
  Gestión_de_venta_con_adelanto: 3,
  Forma_de_pago: 4,
};
