import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Card,
  Alert,
} from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import moment from "moment";

import "./ContractsList.css";

import validationPlate from "helpers/validationPlate";
import ModalContracts from "Views/ContractsList/components/ModalContracts";
import { ErrorPanel, WarningPanel } from "components/InfoBox";
import { axiosInstance } from "services/BaseService";
import { errorToast } from "helpers/toastFunction";

const ContractsList = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      searchType: "matricula",
    },
  });

  const searchType = watch("searchType");
  const imported = watch("imported");

  const [contracts, setContracts] = useState(undefined);

  const [showModal, setShowModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);

  const validateSearchInput = (value) => {
    if (value.trim() === "") return "El campo no puede estar vacío";

    if (searchType === "matricula" && !validationPlate(value, imported))
      return "Matrícula incorrecta";

    if (
      searchType === "bastidor" &&
      !/^\b[(A-H|J-N|P|R-Z|0-9)]{17}\b$/i.test(value)
    )
      return "Bastidor incorrecto";

    return true;
  };

  const handleSearch = async (formData) => {
    try {
      const URL_BASE = `/contract?${
        formData.searchType === "matricula" ? "plate" : "chassisNumber"
      }=${formData.search}`;

      const response = await axiosInstance.get(URL_BASE);
      setContracts(response);
    } catch (err) {
      errorToast("Error al buscar contratos");
    }
  };

  const handleShowModal = (contract) => {
    setSelectedContract(contract);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContract(null);
  };

  return (
    <Container fluid className="px-4">
      <Row>
        <Col className="col-12">
          <div className={`clearfix mt-3 mb- containerTitle`}>
            <span className="tittle ml-4">Seleccionar Contrato</span>
          </div>
        </Col>
      </Row>

      <form
        onSubmit={handleSubmit(handleSearch)}
        className="d-flex flex-column mt-3"
      >
        <div className="d-flex align-items-center gap-3">
          <Form.Check
            type="radio"
            label="Matrícula"
            value="matricula"
            className="custom-radio"
            {...register("searchType")}
          />

          <Form.Check
            type="radio"
            label="Bastidor"
            value="bastidor"
            name="searchOption"
            className="custom-radio"
            {...register("searchType")}
          />

          <Form.Control
            type="text"
            style={{ width: "auto" }}
            placeholder={searchType === "matricula" ? "Matrícula" : "Bastidor"}
            className={`${errors.search && "is-invalid"}`}
            {...register("search", {
              validate: validateSearchInput,
            })}
          />

          <Button className="btn_custom" type="submit">
            Buscar
          </Button>

          {errors.search && (
            <ErrorPanel className="error" text={errors.search.message} />
          )}
        </div>

        {searchType === "matricula" && (
          <Form.Check
            type="checkbox"
            label="Importado"
            className="mt-3"
            {...register("imported")}
          />
        )}
      </form>

      {contracts && contracts.length === 0 && (
        <Row className="mt-4">
          <Col>
            <Alert variant="warning">
              No se han encontrado contratos con la información indicada.
            </Alert>
          </Col>
        </Row>
      )}

      <div className="mt-4 d-flex flex-column gap-3">
        {contracts &&
          contracts.length > 0 &&
          contracts.map((contract) => (
            <Card key={`contract-${contract.id}`}>
              <div className="d-flex flex-column py-3 mx-5 gap-3">
                <div className="d-flex justify-content- align-items-center gap-5">
                  <span className="d-flex flex-column">
                    <strong>Fecha de contrato</strong>
                    {moment(new Date(contract.contract_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                  <span className="d-flex flex-column">
                    <strong>DNI/CIF</strong> {contract.identification_number}
                  </span>
                  <span className="d-flex flex-column">
                    <strong>Nombre y Apellidos</strong> {contract.client}
                  </span>
                  <span className="d-flex flex-column">
                    <strong>Matrícula</strong> {contract.plate}
                  </span>
                  <span className="d-flex flex-column">
                    <strong>Bastidor</strong> {contract.chassis_number}
                  </span>
                  {!contract.task_id && (
                    <Button
                      className="btn_custom align-self-end"
                      style={{ marginLeft: "auto" }}
                      variant="primary"
                      onClick={() => handleShowModal(contract)}
                    >
                      Seleccionar
                    </Button>
                  )}
                </div>

                {contract.task_id && (
                  <div className="d-flex justify-content-between align-items-center">
                    <WarningPanel text="El contrato ya tiene una gestión creada" />
                    <div
                      className="d-flex align-items-center gap-2"
                      style={{
                        color: "#D34600",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/gestion/${contract.task_id}`)}
                    >
                      <span>Ver gestión</span>
                      <FaEye />
                    </div>
                  </div>
                )}
              </div>
            </Card>
          ))}
      </div>

      {showModal && (
        <ModalContracts
          show={showModal}
          handleClose={handleCloseModal}
          selectedContract={selectedContract}
        />
      )}
    </Container>
  );
};

export default ContractsList;
