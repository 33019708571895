import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container, Spinner, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";

import "../Payment.css";
import { getTasks, updatePayment } from "../../../services/axiosCall";
import Pagination from "../../../components/Pagination/Pagination";
import { useUserContext } from "contexts/UserContext";


const ConfirmPay = () => {
  const { user } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTotal, setSelectedTotal] = useState(0);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);

  const [actualPage, setActualPage] = useState(1);
  const [limit] = useState(300);
  const [tasksCount, setTasksCount] = useState(null);
  const [societyFilter, setSocietyFilter] = useState(null);
  const [selected, setSelected] = useState([]);
  const [sum, setSum] = useState(0);
  const initialOffset = parseFloat(searchParams.get("offset")) || 0
  const [offset, setOffset] = useState(initialOffset);  

  const getTasksHandler = useCallback(async () => {
    setLoading(false);
    setSelected([]);
    setTasks(null);
    setSum(0);
    setSelectedTotal(0);
    if (user.id) {
      const response = await getTasks({
        limit: limit,
        offset: offset,
        society: societyFilter,
        pay: 2,
        incidence: [2],
        completed: false,
        userId: user.id,
        order: "ASC",
      });
      const taskReadytoPay = response.data;
      setTasks(taskReadytoPay);
      setTasksCount(response.count);

      const allIdSelection = taskReadytoPay.map((item) => ({
        id: item.id,
        importe: +(
          item.importe -
          (item.ajuste || 0) -
          (item.domain?.importe || 0)
        ).toFixed(2),
      }));
      setSelected(allIdSelection);
      setSelectedTotal(allIdSelection.length);

      const totalSum = allIdSelection.reduce((acc, item) => (acc + item.importe),0);
      setSum(totalSum.toFixed(2));
    }
  }, [limit, offset, societyFilter, user.id]);

  const literalHandler = (id) => {
    if (user.dealershipOptions) {
      const [local] = user.dealershipOptions.filter(
        (item) => item.value === id
      );
      if (local?.identificador) {
        return local.identificador;
      } else {
        return zeroFill(id, 5);
      }
    }
  };

  const zeroFill = (number, width) => {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
    }
    return number + "";
  };

  useEffect(() => {
    getTasksHandler();
  }, [getTasksHandler]);

  const handleCompletePay = async () => {
    try {
      setLoading(true);
      const data = {
        id: selected.map((item) => item.id),
        pago: 3,
        fechaPago: new Date().toISOString().split("T")[0],
      };
       await updatePayment(data);
      getTasksHandler();
    } catch (error) {
      setLoading(false);
    }
  };

  const companyHandler = (id) => {
    if (user.companiesOptions) {
      const [company] = user.companiesOptions.filter((item) => item.value === id);
      return company.label;
    }
  };

  const totalImportHandler = (importe, ajuste, domain) => {
    const number = (importe - (ajuste || 0) - (domain || 0)).toFixed(2);
    let str = number.toString().split(".");
    //str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");  //añade un punto cada 3 cifras
    return str.join(",");
  };

  const selectHandler = (id, importe, type) => {
    let selection = selected;
    let idArray = [];
    let totalSum = 0;

    selection.forEach((item) => {
      idArray.push(item.id);
    });
    if (!idArray.includes(id)) {
      selection.push({ id, importe });
    } else {
      let removeIndex = selection.map((item) => item.id).indexOf(id);
      selection.splice(removeIndex, 1);
    }
    selection.forEach((item) => (totalSum += item.importe));
    setSum(totalSum.toFixed(2));
    setSelected(selection);
    setSelectedTotal(selection.length);
  };

  const filterHandler = (data) => {
    if (actualPage > 1){
      setOffset(0)
      setActualPage(1)
      searchParams.delete("offset")
      searchParams.delete("page")
    };
    if (data.type === "society" && data.item.length !== 0) {
      setTasks(null);
      setSocietyFilter(data.item.map((item) => item.value));
    } else if (data.type === "society" && data.item.length === 0) {
      setTasks(null);
      setSocietyFilter(undefined);
    }
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className={`clearfix mt-3 mb-3 containerTitle`}>
            <span className="tittle ml-4">Confirmar firma</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-5 custom__relative">
        <h6>Filtros:</h6>

        <Col xl={2}>
          <Select
            placeholder="Seleccione sociedad"
            onChange={(item) => filterHandler({ item, type: "society" })}
            options={user.companiesOptions}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
      </Row>

      <Row>
        <Row>
          <h6>Tareas seleccionadas: {selectedTotal}</h6>
        </Row>
        <Col xl={10}>Importe Total: {sum} €</Col>
        <Col xl={2}>
          <Row>
            <Col>
              <h6 className="mt-2">Resultados: {tasksCount}</h6>
            </Col>

            <Col>
              <div class="container">
                {!loading ? (
                  <Button
                    disabled={selected.length !== 0 ? false : true}
                    className="btn_custom mb-2"
                    onClick={handleCompletePay}
                  >
                    Confirmar
                  </Button>
                ) : (
                  <Button className="btn_custom" disabled>
                    <Spinner
                      variant="warning"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Container fluid>
        <Row className="custom__header">
          <Col>
            <h5 className="input__id mt-2">Identificador</h5>
          </Col>
          <Col>
            <h5 className="input__society mt-2">Sociedad</h5>
          </Col>
          <Col>
            <h5 className="input__importe mt-2">Importe</h5>
          </Col>
          <Col>
            <h5 className="input__iban mt-2">IBAN</h5>
          </Col>
          <Col>
            <h5 className="input__titular mt-2">Titular</h5>
          </Col>

          <Col>
            <h5 className="input__concepto mt-2">Concepto</h5>
          </Col>

          <Col>
            <h5 className="input__checkbox mt-2">Confirmar firma</h5>
          </Col>
        </Row>
      </Container>

      {tasks ? (
        tasks.length > 0 ? (
          tasks.map((item) => (
            <Container fluid>
              <Row>
                <hr className="custom__line" />
              </Row>
              <Row className="my-2">
                <Col>
                  <h5>{item.id}</h5>
                </Col>
                <Col>
                  <h5>{companyHandler(item.society)}</h5>
                </Col>
                <Col>
                  <input
                    className="input__importe"
                    defaultValue={totalImportHandler(
                      item.importe,
                      item.ajuste,
                      item.domain?.importe
                    )}
                    readOnly
                  />
                </Col>
                <Col>
                  <input
                    className="input__iban"
                    defaultValue={item.iban}
                    readOnly
                  />
                </Col>
                <Col>
                  <input
                    className="input__titular"
                    defaultValue={item.titular}
                    readOnly
                  />
                </Col>

                <Col>
                  <input
                    className="input__concepto"
                    defaultValue={`P-Compra-${item.id}-${
                      item.matricula
                    }-${literalHandler(item.local)}`}
                    readOnly
                  />
                </Col>
                <Col>
                  <Row>
                    <Col md={8} className="d-flex justify-content-center">
                      <Form.Check
                        className="input__checkbox"
                        onClick={() =>
                          selectHandler(
                            item.id,
                            +(
                              item.importe -
                              (item.ajuste || 0) -
                              (item.domain?.importe || 0)
                            ).toFixed(2),
                            2
                          )
                        }
                        defaultChecked={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          ))
        ) : (
          <div className="center">
            <h4 className="custom__space">
              No existen tareas pendientes de pago
            </h4>
          </div>
        )
      ) : (
        <div className="center">
          <h4 className="custom__space">
            <Spinner animation="border" variant="warning" />
          </h4>
        </div>
      )}
      {tasksCount && (
        <Pagination
          actualPage={actualPage}
          tasksCount={tasksCount}
          limit={limit}
          setActualPage={setActualPage}
          setSearchParams={setSearchParams}
          setOffset={setOffset}
        />
      )}
    </>
  );
};

export default ConfirmPay;
