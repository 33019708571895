import { getRefreshToken, setTokens } from "store/AccessTokenStore";
import { axiosInstance } from "./BaseService";

export const login = async (body) => {
  try {
    return await axiosInstance.post("/auth/login", body);
  } catch (error) {}
};

export const refreshTokens = async () => {
  try {
    const response = await axiosInstance.post(
      "/auth/refresh",
      {},
      {
        headers: {
          authorization: `Bearer ${getRefreshToken()}`,
        },
        validateStatus: null,
      }
    );
    setTokens(response);
  } catch (err) {}
};

export const getAllTask = (data) => axiosInstance.post("/tasks/filtered", data);

export const getOneTask = async (route) => {
  try {
    return await axiosInstance.get(route);
  } catch (e) {}
};
