import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

import "./Login.css";
import { useUserContext } from "contexts/UserContext";

const Login = () => {
  const { logIn } = useUserContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [authError, setAuthError] = useState(null);
  const { state: locationState } = useLocation();

  const onSubmit = async (data) => {
    setAuthError(null);

    try {
      await logIn(data, locationState);
    } catch (error) {
      setAuthError("auth");
    }
  };

  return (
    <div>
      <Row>
        <Col Col xs={8} md={6} lg={4} xl={3} className="centered p-5 border">
          <Row>
            <h4>Inicio de sesión</h4>
          </Row>
          <Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className={`my-2 ${
                    (errors.email || authError) && "is-invalid"
                  }`}
                  type="email"
                  placeholder="Correo electrónico"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <div className="invalid-feedback">
                    <p>Introduzca el email</p>
                  </div>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  className={(errors.password || authError) && "is-invalid"}
                  type="password"
                  placeholder="Contraseña"
                  {...register("password", { required: true })}
                />

                {errors.password && (
                  <div className="invalid-feedback">
                    <p>Introduzca la contraseña</p>
                  </div>
                )}
                {authError === "auth" && !errors.password && (
                  <div className="invalid-feedback">
                    <p>Contraseña incorrecta</p>
                  </div>
                )}
              </Form.Group>
              <Row>
                <Col>
                  <Button className="my-3" type="submit" variant="warning">
                    Acceder
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
