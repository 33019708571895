import MenuItems from "./MenuItems";
import "./Navbar.css";
import getMenu from "./menuItem";
import { useUserContext } from "contexts/UserContext";

const Navbar = () => {
  const { user } = useUserContext();

  const menuItems = getMenu(user.roles);

  return (
    <nav className="navbar-brand">
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
